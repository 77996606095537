###
Wheels movement code shared between r2-car.vue and the fleet carousel vehicles
components.
###
import debounce from 'lodash/debounce'
frameLoadIncrement = 1

export default

	data: ->
		frames: [] # The array of Image objects
		cachedFrames: [] # The Array of the cached image objects
		backFrames: [] # The array of the back wheel frames if needed
		cachedBackFrames: [] # The Array of the cached image objects
		framesLoaded: 0 # The number of frames loaded
		wheelWidth: null # The width of the wheel in pixels
		wheelHeight: null # The height of the wheel in pixels
		cachedWidth: null
		lastFrame: 720 # The number of frames per 100vw
		initLoad: false

	computed:
		# The load progress of the frames
		loadProgress: ->
			return 0 unless @frames.length
			@framesLoaded / @frames.length

		# all frames loaded
		loadComplete: -> @loadProgress == 1

		# If no divider is given, use all frames
		computedDividedBy: -> @dividedBy || 1

	watch:
		# Draw new frame
		currentFrame: -> @drawCurrentFrame()

		# When all frames are loaded, draw the current frmae. This is a cheap way to get the image to re-render during HMR.
		# Additionally, when load is complete, if the new wheel width is greater thant he cached width, reinit canvas with the new size
		loadComplete: (complete) ->
			if !@initLoad then @initLoad = true

			if complete and @cachedWidth and @cachedWidth < @wheelWidth
				@initCanvas()

			if complete
				@drawCurrentFrame() if complete

	# cleanup
	beforeDestroy: ->
		window.removeEventListener 'resize', @onResize

	methods:
	 	# Modulus operator without negative numbers
		mod: (m, n) => ( (m % n) + n ) % n

		# Setup canvas
		initCanvas: ->
			return unless @$refs.front and @$refs.back

			@$refs.front.width = @round @wheelWidth
			@$refs.front.height = @round @wheelHeight

			@$refs.back.width = @round @wheelWidth
			@$refs.back.height = @round @wheelHeight

			@rightCanvas = @$refs.front.getContext '2d'
			@leftCanvas = @$refs.back.getContext '2d'

		# Load all of the frames all at once and let browser sort it out
		loadFrames: ->
			@frames = []
			if @separateWheels then @backFrames = []
			@framesLoaded = 0
			for index in [0...@lastFrame] by frameLoadIncrement
				if @calcFrame index
					if @separateWheels
						imgBack = new Image(@wheelWidth, @wheelHeight)
						imgBack.src = @imgBackSrc index
						@framesBack.push imgBack

					img = new Image(@wheelWidth, @wheelHeight)
					img.onload = () => @framesLoaded++
					img.src = @imgSrc index
					@frames.push img
				else
					if @separateWheels
						@framesBack.push(@framesBack[@reuseFrame index])

					@frames.push(@frames[@reuseFrame index])
					@framesLoaded++

		# Helper to round with 2 decimal places
		round: (num) -> Math.round(num * 100)/100
