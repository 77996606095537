import { render, staticRenderFns } from "./r2-mouse-tracker.vue?vue&type=template&id=467d4848&scoped=true&lang=pug"
import script from "./r2-mouse-tracker.vue?vue&type=script&lang=coffee"
export * from "./r2-mouse-tracker.vue?vue&type=script&lang=coffee"
import style0 from "./r2-mouse-tracker.vue?vue&type=style&index=0&id=467d4848&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "467d4848",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {R2Car: require('/opt/build/repo/site/components/globals/r2-car.vue').default})
