###
Data pertaining the movement of the wheels of the r2-car.vue component
###

export default

	data: ->
		wheelProgress:  # Progress from 0 to 1
			value: 0
		framesPerRevolution: 720 # the number of frames per revolution

	computed:
	 	# The current frame to be rendered
		currentFrame: -> Math.round @wheelProgress.value * (@frames.length - 1)

	# add listeners
	mounted: ->
		@setDimensions()
		window.addEventListener 'resize', @onResize

	methods:
		# Check if frame should be loaded, or reuse existing one
		calcFrame: (index) ->
			(index + 1 <= Math.round @framesPerRevolution/@computedDividedBy)

		# Get the index of the frame to be reused
		reuseFrame: (index) ->
			( index + 1 ) % Math.round @framesPerRevolution/@computedDividedBy

		drawCurrentFrame: ->
			return unless @rightCanvas and @leftCanvas

	 		# If loading new frames and have cached frames, use the cached frames instead
			frame = if !@loadComplete and @cachedFrames.length then @cachedFrames[@currentFrame] else @frames[@currentFrame]

			@rightCanvas.clearRect 0, 0, @wheelWidth, @wheelHeight
			@rightCanvas.drawImage frame, 0, 0, @wheelWidth, @wheelHeight

			@leftCanvas.clearRect 0, 0, @wheelWidth, @wheelHeight
			@leftCanvas.drawImage frame, 0, 0, @wheelWidth, @wheelHeight


