
import Visual from 'vue-visual'
import inViewportMixin from 'vue-in-viewport-mixin'
import R2Wheels from '/mixins/r2-wheels-movement.coffee'
import BaseWheels from '/mixins/wheels-movement-base'
import debounce from 'lodash/debounce'


export default

	components:
		'visual': Visual

	mixins: [ inViewportMixin, R2Wheels, BaseWheels ]

	props:
		show: # `true`: animate in. `false`: animate out.  "inViewport": auto-animate in when this block enters the viewport.
			type: [Boolean, String]
			default: false
			validator: (val) -> val in [true, false, 'inViewport']
		trackMouse: # Should R2 follow your mouse? 🐭
			type: Boolean
			default: true
		driveInFrom: # Drive in from left, or from right?
			type: String
			default: 'left'
			validator: (val) -> val in ['left', 'right']
		stopPosition: # Car's position after it animates in, before any mouse tracking. Use this to make it stop off-center. (Units are xPercent, -50 is center.)
			type: Number
			default: -50
		showRoad: # Show road?
			type: Boolean
			default: true
		inViewportOnce: # Not an actual prop. Just setting this to true. Used by inViewportMixin.
			type: Boolean
			default: true

	data: ->
		computedShow: false
		init: false
		duration: 2
		currentTime:
			value: 0
		acceleration:
			value: 0
			prevTime: 0
		maxTilt: 2
		timeline: {}
		vw: 0

		# Used by wheel mixin to leverage wheel symmetry and reduce amount of frames used
		# Example: If dividedBy == 6, it means that we can represent the whole wheel with only the contents from 0 to 60degree.
		dividedBy: 6

	computed:
		classes: -> [
			if @showRoad then 'show-road' else 'hide-road'
			if @driveInFrom=='left' then 'facing-right' else 'facing-left'
		]
		carTopImage: -> "/car/top-#{if @driveInFrom=='left' then 'right' else 'left'}.png"

	mounted: ->
		# Car
		carStartX = if @driveInFrom=='left' then -120 else 20
		@$gsap.set @$refs.wrap2, {xPercent:carStartX, autoAlpha:0} # Hide, to prevent jank
		@$gsap.set @$refs.wrap, {x:'-80vw'}
		@$gsap.set @$refs.road, {autoAlpha:0, scaleY:0}
		@initTimeline()
		@updateComputedShow()
		window.addEventListener 'resize', @handleWindowResize if @trackMouse

	destroyed: ->
		window.removeEventListener 'resize', @handleWindowResize if @trackMouse
		@removeMouseEvents() if @trackMouse

	watch:
		'inViewport.now': (inViewport) -> @updateComputedShow()
		'show': (show) -> updateComputedShow()

	methods:
		# Make the src URL at a given index
		# Images uploaded to cloud.digitalocean.com/spaces/nuro
		# used by wheelMovement mixin
		imgSrc: (index) ->
			num = @$padNum index + 1, 5
			"https://nuro.imgix.net/Carousel_Wheels/R3/030922_R3_720-#{num}.png?\
				w=#{@wheelWidth}&h=#{@wheelHeight}&ar=#{@wheelWidth}:#{@wheelHeight}&auto=compress"

		updateComputedShow: ->
			@computedShow =
				if @show=='inViewport'
					@inViewport.now
				else
					@show
			if @computedShow then @showCar() else @hideCar()

		showCar: ->
			@setVw()
			@currentTime.value = if @driveInFrom=='left' then 0 else @duration
			@handleMouseMove({pageX: @vw/2})
			@$gsap.set @$refs.wrap2, {autoAlpha:1} # Show
			@$gsap.to @$refs.wrap2, 1.6, {xPercent:@stopPosition, ease:'power4.out'}
			@$gsap.to @$refs.road, 0.8, {autoAlpha:1, scaleY:1, ease:'power1.inOut'}
			@addMouseEvents() if @trackMouse
			@$refs.wrap.style.opacity = 1

		hideCar: ->
			@removeMouseEvents() if @trackMouse
			@handleMouseMove({pageX: 0})
			@$gsap.to @$refs.wrap2, 0.6, {xPercent:-160, ease:'power1.in'}
			@$gsap.to @$refs.road, 0.3, {autoAlpha:0, scaleY:0}, 0

		initTimeline: () ->
			container = @$refs.wrap
			road = @$refs.road
			front = @$refs.front
			top = @$refs.top.$el

			wheelCircumference = front.offsetWidth * 1 * 3.14
			wheelTurnsPer100VW = window.innerWidth / wheelCircumference

			@lastFrame = Math.round(@framesPerRevolution * wheelTurnsPer100VW)
			@loadFrames()


			@timeline = @$gsap.timeline()
				.fromTo container, @duration, {x:'-40vw'}, {x:'40vw', ease:'linear'}
				.fromTo @wheelProgress, @duration, {value: 1}, {value: 0, ease:'linear'}, 0
				.pause(0)

		addMouseEvents: () ->
			@$refs.wrap3.addEventListener 'mousemove', @handleMouseMove

		removeMouseEvents: () -> @$refs?.wrap3?.removeEventListener 'mousemove', @handleMouseMove

		handleWindowResize: () ->
			@initTimeline()
			@setVw()

		handleMouseMove: (e) ->
			newTime = (e.pageX / @vw) * @duration
			@acceleration.value = Math.max Math.min((@acceleration.prevTime - newTime) * 100, @maxTilt), @maxTilt*-1
			@acceleration.prevTime = newTime
			top = @$refs.top.$el

			@$gsap.to @acceleration, 0.8,
				value: 0
				overwrite: true
				ease: 'power1.out'
			@$gsap.to @currentTime, 2,
				value: newTime
				overwrite: true
				ease: 'power1.out'
				onUpdate: =>
					@timeline.pause @currentTime.value
					@$gsap.to top, 0.3, {rotation: @acceleration.value}

		setVw: -> @vw = window.innerWidth

		###
		All the components that render the wheel frames could share the two functions
		below in a mixin, but doing that introduces a bug where not all components that
		consume the mixin executes the debounce function on event listeners
		###

		# Reset/start wheel sizes and canvas if necessary
		setDimensions: ->
			return unless @$refs.front
			rect = @$refs.front.getClientRects()[0]

			if !@wheelWidth
				@wheelWidth = rect.width
				@wheelHeight = rect.width
				@initCanvas()
				@loadFrames()

			else if @wheelWidth < rect.width
				@cachedWidth = @wheelWidth
				@wheelWidth = rect.width
				@wheelHeight = rect.width
				@cachedFrames = [...@frames]
				@loadFrames()

		# Debounce expensive function
		onResize: debounce ( -> @setDimensions()), 1000

